import "../css/lib.css";
import "../css/main.css";
import "../css/normalize.css";
// import "../style.css";

import * as queryString from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation } from "@reach/router";
import { RiseLoader } from "react-spinners";
import { questionsDataStoreOffline } from "../data";
import { Typography, Button } from "@material-ui/core";

import { useStyles } from "../style";
import { Question } from "../types";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

const questions = [
  {
    q: `Mức độ yêu thích của anh/ chị với ấn phẩm Lịch Cao Cấp 2024 của PNJ?`,
    type: `option`,
    options: [
      {
        value: `Rất Thích`,
        label: `Rất Thích`,
      },
      {
        value: `Thích`,
        label: `Thích`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không Thích`,
        label: `Không Thích`,
      },
      {
        value: `Hoàn Toàn Không Thích`,
        label: `Hoàn Toàn Không Thích`,
      },
    ],
  },
  {
    q: `Mức độ phù hợp của ấn phẩm Lịch Cao Cấp 2024 của PNJ so với nhu cầu của anh/ chị?`,
    type: `option`,
    options: [
      {
        value: `Rất phù hợp`,
        label: `Rất phù hợp`,
      },
      {
        value: `Phù hợp`,
        label: `Phù hợp`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không phù hợp`,
        label: `Không phù hợp`,
      },
      {
        value: `Hoàn toàn không phù hợp`,
        label: `Hoàn toàn không phù hợp`,
      },
    ],
  },
  {
    q: `Điểm yêu thích nhất của anh/chị về ấn phẩm Lịch Cao Cấp 2024 của PNJ? Vui lòng cho biết rõ.`,
    type: `text`,
  },
  {
    q: `Điểm gì khiến anh/ chị không thích về ấn phẩm Lịch Cao Cấp 2024 của PNJ? Vui lòng cho biết rõ.`,
    type: `text`,
  },
  {
    q: `Với mong muốn người dùng cảm nhận được sự tâm huyết của PNJ qua việc chú trọng từng tiểu tiết trong thiết kế lịch. PNJ mang đến 1 trải nghiệm mới - người dùng sẽ trực tiếp lắp ghép các chi tiết của bộ lịch như: rồng kim loại, tranh, khánh lịch, tờ lịch,….thành 1 ấn phẩm lịch hoàn chỉnh. Vui lòng cho biết mức độ thích của anh/ chị về trải nghiệm mới này?`,
    type: `option`,
    options: [
      {
        value: `Rất Thích`,
        label: `Rất Thích`,
      },
      {
        value: `Thích`,
        label: `Thích`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không Thích`,
        label: `Không Thích`,
      },
      {
        value: `Hoàn Toàn Không Thích`,
        label: `Hoàn Toàn Không Thích`,
      },
    ],
  },
  {
    q: `Sau khi hoàn thành lắp ghép ấn phẩm Lịch Cao Cấp 2024, anh/ chị đánh giá như thế nào về độ hoàn thiện và kết cấu của ấn phẩm này?`,
    type: `option`,
    options: [
      {
        value: `Rất hoàn thiện`,
        label: `Rất hoàn thiện`,
      },
      {
        value: `Hoàn thiện`,
        label: `Hoàn thiện`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không hoàn thiện`,
        label: `Không hoàn thiện`,
      },
      {
        value: `Hoàn toàn không hoàn thiện`,
        label: `Hoàn toàn không hoàn thiện`,
      },
    ],
  },
  {
    q: `Mức độ độc đáo/ khác biệt của ấn phẩm Lịch Cao Cấp 2024 của PNJ so với các sản phẩm lịch khác có trên thị trường?`,
    type: `option`,
    options: [
      {
        value: `Rất độc đáo và khác biệt`,
        label: `Rất độc đáo và khác biệt`,
      },
      {
        value: `Độc đáo và khát biệt`,
        label: `Độc đáo và khát biệt`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không độc đáo, khác biệt`,
        label: `Không độc đáo, khác biệt`,
      },
      {
        value: `Hoàn toàn không độc đáo, khác biệt`,
        label: `Hoàn toàn không độc đáo, khác biệt`,
      },
    ],
  },
  {
    q: `Ấn phẩm Lịch Cao Cấp 2024 của PNJ được nằm trong hộp có chất liệu và thiết kế sang trọng. Khi mở ra, người nhận sẽ nhận được lời ngỏ từ PNJ với thông điệp 'Tạo Tinh Tuý - Trao Tinh Hoa'. PNJ trân quý từng khoảnh khắc trong quá trình tạo tác của chính mình, tự hào trao tinh hoa để truyền cảm hứng và cùng với anh/ chị viết tiếp hành trình vươn tầm trong năm mới. Vui lòng cho biết mức độ thích của anh/ chị đối với thông điệp :`,
    type: `option`,
    options: [
      {
        value: `Rất Thích`,
        label: `Rất Thích`,
      },
      {
        value: `Thích`,
        label: `Thích`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không Thích`,
        label: `Không Thích`,
      },
      {
        value: `Hoàn Toàn Không Thích`,
        label: `Hoàn Toàn Không Thích`,
      },
    ],
  },
  {
    q: `Anh/ Chị có cảm nhận như thế nào về chất liệu của ấn phẩm Lịch Cao Cấp 2024?`,
    type: `option`,
    options: [
      {
        value: `Rất tốt`,
        label: `Rất tốt`,
      },
      {
        value: `Tốt`,
        label: `Tốt`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không tốt`,
        label: `Không tốt`,
      },
      {
        value: `Hoàn toàn không tốt`,
        label: `Hoàn toàn không tốt`,
      },
    ],
  },
  {
    q: `Anh/ Chị đánh giá như thế nào về kích thước của ấn phẩm Lịch Cao Cấp 2024?`,
    type: `option`,
    options: [
      {
        value: `Quá nhỏ so với nhu cầu của tôi`,
        label: `Quá nhỏ so với nhu cầu của tôi`,
      },
      {
        value: `Hơi nhỏ so với nhu cầu của tôi`,
        label: `Hơi nhỏ so với nhu cầu của tôi`,
      },
      {
        value: `Kích cỡ vừa phải, đáp ứng đúng nhu cầu của tôi`,
        label: `Kích cỡ vừa phải, đáp ứng đúng nhu cầu của tôi`,
      },
      {
        value: `Hơi to so với nhu cầu của tôi`,
        label: `Hơi to so với nhu cầu của tôi`,
      },
      {
        value: `Quá to so với nhu cầu của tôi`,
        label: `Quá to so với nhu cầu của tôi`,
      },
    ],
  },
  {
    q: `Anh/chị có góp ý gì cho cho ấn phẩm Lịch Cao Cấp 2024 này không? Vui lòng cho biết rõ.`,
    type: `text`,
  },
  {
    q: `Anh/ Chị có cảm nhận như thế nào về chất liệu của ấn phẩm Lịch Cao Cấp 2024?`,
    type: `option`,
    options: [
      {
        value: `Rất Thích`,
        label: `Rất Thích`,
      },
      {
        value: `Thích`,
        label: `Thích`,
      },
      {
        value: `Bình Thường`,
        label: `Bình Thường`,
      },
      {
        value: `Không Thích`,
        label: `Không Thích`,
      },
      {
        value: `Hoàn Toàn Không Thích`,
        label: `Hoàn Toàn Không Thích`,
      },
    ],
  },
  {
    q: `Anh/ Chị đánh giá như thế nào về kích thước của ấn phẩm Lịch Cao Cấp 2024?`,
    type: `option`,
    options: [
      {
        value: `Quá nhỏ so với nhu cầu của tôi`,
        label: `Quá nhỏ so với nhu cầu của tôi`,
      },
      {
        value: `Hơi nhỏ so với nhu cầu của tôi`,
        label: `Hơi nhỏ so với nhu cầu của tôi`,
      },
      {
        value: `Kích cỡ vừa phải, đáp ứng đúng nhu cầu của tôi`,
        label: `Kích cỡ vừa phải, đáp ứng đúng nhu cầu của tôi`,
      },
      {
        value: `Hơi to so với nhu cầu của tôi`,
        label: `Hơi to so với nhu cầu của tôi`,
      },
      {
        value: `Quá to so với nhu cầu của tôi`,
        label: `Quá to so với nhu cầu của tôi`,
      },
    ],
  },
  {
    q: `Anh/chị có góp ý gì cho cho ấn phẩm Lịch Cao Cấp 2024 này không? Vui lòng cho biết rõ.`,
    type: `text`,
  },
];

// markup
const CalendarSurvey = () => {
  const classes = useStyles();
  const location = useLocation();
  const toast = useToast();
  const { token } = queryString.parse(location.search);

  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const methods = useForm();
  const {
    register,
    formState: { errors, isValid, isDirty },
  } = methods;

  const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    });
  };

  const validateToken = useCallback(async () => {
    if (token === ``) {
      setIsValidToken(false);
      setIsValidating(true);
      setLoading(true);
    } else {
      axios
        .post(`${process.env.GATSBY_CDP_END_POINT}/validators-api/v1/validate-leadform-token`, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_id: 13,
          token: token,
        })
        .then((result) => {
          if (result.data.success && result.data.data.is_valid) {
            setIsValidToken(true);
            setIsValidating(false);
            setLoading(false);
          }
        })
        .catch(function (error) {
          setIsValidToken(false);
          setIsValidating(false);
          setLoading(false);
        });
    }
  }, [token]);

  const onSubmit = (data: any) => {
    const prepareData: any = {};
    for (let index = 0; index < Object.keys(data).length; index++) {
      prepareData[`question${index + 1}`] = {
        question: questions[index].q,
        anwser: data[index],
      };
    }
    axios
      .post(`${process.env.GATSBY_CDP_END_POINT}/validators-api/v1/save-leadform-token-customer`, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_id: 13,
        token: token,
        // eslint-disable-next-line @typescript-eslint/camelcase
        cust_phone: ``,
        // eslint-disable-next-line @typescript-eslint/camelcase
        cust_mail: ``,
        // eslint-disable-next-line @typescript-eslint/camelcase
        cust_name: ``,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_source: `brand_calendar_2024_survey`,

        // eslint-disable-next-line @typescript-eslint/camelcase
        visitor_id: ``,
        data: prepareData,
      })
      .then(function (response) {
        if (response.status == 200) {
          setIsValidToken(false);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // check unique link
  useEffect(() => {
    validateToken();
  }, [validateToken]);

  return (
    <main className={classes.body}>
      {loading && (
        <div className={classes.overlayLoading}>
          <RiseLoader
            color="#fff"
            style={{ display: `flex`, justifyContent: `center`, alignItems: `center`, height: `100vh` }}
          />
        </div>
      )}
      {!loading && (isValidating || !isValidToken) && <ThankYouComponent />}
      {!loading && !(isValidating || !isValidToken) && (
        <FormProvider {...methods}>
          <div className="container_store_ratings" style={{ overflowY: `scroll`, padding: `10px` }}>
            <Typography variant="subtitle2" style={{ color: `#003468`, fontWeight: `bold`, marginBottom: `15px` }}>
              PHẢN HỒI VỀ ẤN PHẨM LỊCH CAO CẤP 2024 CỦA PNJ
            </Typography>
            <Typography variant="body2" style={{ color: `#003468`, fontWeight: `lighter`, marginBottom: `15px` }}>
              Xin chào anh/ chị,
            </Typography>
            <Typography variant="body2" style={{ color: `#003468`, fontWeight: `lighter`, marginBottom: `15px` }}>
              Trân quý khoảnh khắc, tâm huyết và tầm nhìn được đặt vào trong từng sản phẩm, PNJ gói trọn chân thành và
              gửi tặng đến anh/chị ấn phẩm Lịch Cao Cấp 2024 với mong muốn được tiếp tục đồng hành, đáp lại những tin
              yêu trong suốt thời gian qua và hướng đến tương lai phía trước trong năm 2024 - Tạo Tinh Tuý, Trao Tinh
              Hoa.
            </Typography>
            <Typography variant="body2" style={{ color: `#003468`, fontWeight: `lighter`, marginBottom: `15px` }}>
              PNJ mong muốn được lắng nghe ý kiến đóng góp và phản hồi từ quý anh/ chị về ấn phẩm này. Anh/chị vui lòng
              dành khoảng 5 phút để trả lời một số câu hỏi dưới đây.
            </Typography>
            <Typography variant="body2" style={{ color: `#003468`, fontWeight: `lighter`, marginBottom: `15px` }}>
              Xin chân thành cảm ơn anh/ chị!
            </Typography>
            <br />
            <br />
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {questions.map((item: any, index: number) => (
                <div key={index}>
                  <div style={{ color: `#003468`, fontWeight: `bold` }}>{`${index + 1}.${item.q}`}</div>

                  <div style={{ padding: `10px 20px 10px 20px` }}>
                    {item.type === `option` &&
                      item.options.map((opt: any, indexOpt: number) => (
                        <div key={`opt${index}${indexOpt}`} style={{ display: `flex`, margin: `5px 0 5px 0` }}>
                          <input
                            style={{ display: `block`, appearance: `auto`, marginRight: `5px` }}
                            type="radio"
                            value={opt.value}
                            {...register(`${index}`, {
                              required: `Xin hãy chọn lý do gửi phản hồi!`,
                            })}
                          />
                          <label style={{ color: `#003468`, fontWeight: `lighter` }}>{opt.label}</label>
                        </div>
                      ))}
                    {item.type === `text` && (
                      <textarea
                        {...register(`${index}`, {
                          required: `Xin hãy chọn lý do gửi phản hồi!`,
                        })}
                        className="text-field-diff"
                        rows={4}
                        style={{ color: `#003468` }}
                      />
                    )}
                    {errors[index] && <p style={{ color: `red` }}>{errors[index]?.message}</p>}
                  </div>
                </div>
              ))}
              <div style={{ textAlign: `center` }}>
                <Button type="submit" variant="contained" color="primary" disabled={!isDirty}>
                  Gửi
                </Button>
              </div>
            </form>
            {loading && (
              <div className={classes.overlayLoading}>
                <RiseLoader
                  color="#fff"
                  style={{
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                    height: `100vh`,
                  }}
                />
              </div>
            )}
          </div>
        </FormProvider>
      )}
    </main>
  );
};

export default CalendarSurvey;

function ThankYouComponent() {
  return (
    <div className={`container_store_ratings`}>
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
        }}
      >
        <img src="/images/diamond.png" loading="lazy" alt="" className="image" />
      </div>
      <div>
        <p className="thank-subtitle-store">Chân thành cám ơn quý khách đã dành thời gian góp ý cho PNJ!​</p>
        <p className="thank-subtitle-store">Trong trường hợp muốn chia sẻ trực tiếp ​​</p>
        <p className="thank-subtitle-store">Hãy gọi đến hotline 1800 54 54 57</p>

        <div className="thankyou-action thankyou-action-mobile">
          <a href="tel:1800545457">Gọi hotline PNJ miễn phí</a>
        </div>
        <div className="thankyou-action thankyou-action-desktop">
          <a>Gọi hotline PNJ miễn phí</a>
        </div>
      </div>
    </div>
  );
}
